@media only screen and (max-width: 1000px) {
  .start-view {
    display: block;
  }

  .start-content {
    width: 100%;
    height: 100%;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .floating-container {
    padding: 30px 40px;
    width: 100%;
    height: 60%;
  }

  .lettering {
    height: 40%;

    .text-container h1 {
      font-size: 2rem;
    }
  }

  .floating-container .content {
    width: fit-content;
    height: fit-content;
    max-width: 600px;
  }

  .logo {
    display: none;
  }

  .blurb {
    padding: 10px;
  }
}

@media only screen and (max-width: 500px) {
  h3 {
    font-size: 1.2rem;
  }

  .floating-container {
    height: 70%;
    padding: 10px 20px 30px 20px;
  }

  .floating-container .content {
    height: 100%;
  }

  .lettering {
    height: 30%;
    
    .text-container {
      margin: 6px 0;

      h1 {
        font-size: 1.5rem;
      }
    }
  }

  .blurb {
    padding: 8px;
  }
  
  .button {
    margin: 5px;

    &.small {
      p {
        font-size: 0.9rem;
      }
    }
  }

  .content .options {
    margin-bottom: 10px;
  }

  .content .column {
    margin: 5px 0;
  }

  .content .row {
    margin: 5px 0;
  }
}

@media only screen and (max-width: 350px) {
  h3 {
    font-size: 1rem;
  }

  .floating-container p {
    font-size: 1rem;
  }

  .button {
    margin: 4px;
    height: 50px;

    &.small {
      p {
        font-size: 0.8rem;
      }
    }
  }
}
